<template>
  <div>Profile index</div>
</template>

<script>
    export default {
        name: "Index"
    }
</script>

<style scoped>

</style>